import React, { useContext } from "react"
import Img from "gatsby-image"
import Hero from "../configurable/Hero"
import Heading from "../configurable/Heading"
import Block from "../configurable/Block"
import Image from "../configurable/Image"
import Icon from "../olc-framework/Icon"
import HighlightBlock from "../configurable/HighlightBlock"
import Grid from "../configurable/Grid"
import { markdownNodesFilter } from "~/utils"
import Col9 from "../grid/Col9"
import Col4 from "../grid/Col4"
import Col8 from "../grid/Col8"
import Col11 from "../grid/Col11"
import Col6 from "../grid/Col6"
import Col7 from "../grid/Col7"
import Col5 from "../grid/Col5"
import Col12 from "../grid/Col12"
import TickList from "../configurable/TickList"
import BlockCTA from "../configurable/BlockCTA"
import "../configurable/ProductsAndWarrantiesBlock/styles.scss"
import "../configurable/BlockCTA/styles.scss"
import { CustomerTypeContext } from "~/providers/CustomerTypeProvider"
import Section from "../configurable/Section"
import "./seg.scss"


const Seg = ({ markdownNodes }) => {
  return (
    <div>
      <Hero imageUrl="/images/banner_new_2.png" compact>
        <Heading level={1} underlined>
          Smart Export Guarantee (SEG) 
        </Heading>
      </Hero>
      <Block className="segBlock"> 
        <Section>
          <div className="container container--column ">
            <Col12>
              <Heading level={1}  >What is SEG?</Heading>
              <p className="bold-formatting">
              The <strong>Smart Export Guarantee (SEG)</strong> scheme launched on 1 January 2020 and is a government-backed initiative. The SEG requires some electricity suppliers (SEG Licensees) to pay small-scale generators (SEG Generators) for <strong>low-carbon electricity</strong> which they export back to the National Grid, providing certain <strong>criteria</strong> are met.
              </p>
            </Col12>
          </div>
        </Section>
        <Section>
          <div className="container ">
            <Col12>
              <Heading level={2} underlined >Who can be a SEG Generator?</Heading>
              <p className="bold-formatting">
              Anyone with one of the following <strong>technology types could benefit</strong> from the SEG (provided they meet the rest of the eligibility criteria) up to a capacity of 5MW, or up to 50kW for micro-CHP:
              </p>
              <div className="seg-ills row" >
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/solar.png')} alt="my image"  />
                  <p>Solar photovoltaic (solar PV)</p>
                </div>
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/wind.png')} alt="my image"  />
                  <p>Wind</p>
                </div>
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/heat.png')} alt="my image"  />
                  <p>Micro combined heat & power (micro-CHP)</p>
                </div>
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/hydro.png')} alt="my image"  />
                  <p>Hydro</p>
                </div>
                <div className="seg-ills__item">
                  <img src={require('../../images/fuels/bio.png')} alt="my image"  />
                  <p>Anaerobic digestion (AD)</p>
                </div>
              </div>
              <div className="seg-ills row" style={{margin:0, padding:0}} >
                <div className="seg-ills__item" style={{maxWidth:"100%", margin:0, padding:0}}>
                  <p style={{minHeight:"auto", fontSize:".9em", fontWeight: "lighter"}}>*These installations must be in Great Britain.</p>
                </div>
              </div>
            </Col12>
          </div>
        </Section>
        <Section>
          <div className="container">
            <Col12>
              <Heading level={2} underlined >Can I be paid for my Excess Generation? </Heading>
              <p className="bold-formatting">
              If you are not in receipt of FIT for an existing installation and the Solar PV; that we have installed, is your only generation, you can receive payment for any excess generation over and above what you have used in the home or stored in batteries if applicable. Then you can select from, a range of energy suppliers who are set up to make SEG Payments. A list of current SEG providers is listed below: 
              </p>

            </Col12>
            <div className="row" style={{marginTop: "2em"}}>
              <Col6>
              <Heading level={4}>
              Mandatory SEG Licensees
              </Heading>
              <TickList>
              <li>British Gas</li>
              <li>Bulb</li>
              <li>E</li>
              <li>EDF Energy</li>
              <li>E.ON</li>
              <li>Octopus Energy</li>
              <li>OVO Energy</li>
              <li>Scottish Power</li>
              <li>Shell Energy</li>
              <li>So Energy</li>
              <li>The Utility Warehouse</li>
              <li>Utilita</li>
              </TickList>
              </Col6>
              <Col6>
              <Heading level={4}>
              Voluntary SEG Licensees
              </Heading>
              <TickList>
              <li>F&S Energy</li><li>
Limejump Energy
</li>
              </TickList>
              <p className="bold-formatting" style={{fontSize:".7em", marginTop:"2em"}}>
              *Taken from Ofgem Website <a href="ofgem.gov.uk/publications/seg-supplier-list"> SEG Supplier List | Ofgem</a>
              </p>
              <p className="bold-formatting">
              You should go to your chosen supplier website, where you will find an online application form. All of the information you require to complete your application can be found in the Handover Pack and additionally, you will require your receipted invoice and export MPAN.  For more information these click <a href="/aftersales">here.</a></p>
              </Col6>
              
            </div>
          </div>
        </Section>
       
      </Block>
    </div>
  )
}

export default Seg
